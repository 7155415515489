//Common Overwrites..

$primary: #33394a;
$primary-light: #626873;
// $primary-hover: #609599;
// $primary-active: #92DEE0;
// $primary-100: #EFF4F5;

$secondary: #b2a05e;
$secondary-light: #e3ce80;
// $secondary-900: #E7C0AA;
// $secondary-500: #FBE5DB;
// $secondary-300: #FCEDE6;
// $secondary-200: #FEF7F4;

$info: #626873;
// $info-active: #FFE8C8;
// $info-hover: #FFF4E4;

$danger: #d23b32;
// $danger-active: #FFE6E4;
// $danger-hover: #F69C97;

// $warning: #B88C1A;
// $warning-active: #FFD27A;
// $warning-hover: #FFF2CF;

$success: #e3ce80;
// $success-active: #DDF1E5;
// $success-hover: #74DD9E;

$light: #eff0f2;

$dark: #202025;
// $dark-900: #505050;
// $dark-500: #8A8A8A;

// $gray: #EDEDED;
// $gray-300: #F2F2F2;
// $gray-200: #F6F6F6;
// $gray-100: #FAFAFA;
$light-gray: #f9fafa;
// $dark-gray: #dad8d8;

$light-purple: #f4f5fa;

$transparent: transparent;
$white: white;
$black: black;

$theme-colors: (
  'primary': $primary,
  'primary-light': $primary-light,
  //   "primary-hover":$primary-hover,
  //   "primary-active":$primary-active,
  //   "primary-100":$primary-100,
  'secondary': $secondary,
  'secondary-light': $secondary-light,
  //   "secondary-900":$secondary-900,
  //   "secondary-500":$secondary-500,
  //   "secondary-300":$secondary-300,
  //   "secondary-200":$secondary-200,
  'info': $info,
  //   "info-active":$info-active,
  //   "info-hover":$info-hover,
  'danger': $danger,
  //   "danger-active":$danger-active,
  //   "danger-hover":$danger-hover,
  //   "warning":$warning,
  //   "warning-active":$warning-active,
  //   "warning-hover":$warning-hover,
  'success': $success,
  //   "success-active":$success-active,
  //   "success-hover":$success-hover,
  'light': $light,
  'dark': $dark,
  //   "dark-900":$dark-900,
  //   "dark-500":$dark-500,
  //   "gray":$gray,
  //   "gray-300":$gray-300,
  //   "gray-200":$gray-200,
  //   "gray-100":$gray-100,
  'light-gray': $light-gray,
  //   "dark-gray":$dark-gray,
  'light-purple': $light-purple,
  'transparent': $transparent,
  'black': $black,
  'white': $white,
);

// $body-color: $black;
// $dropdown-link-color: $black;
// $dropdown-link-hover-color: $white;
// $dropdown-link-hover-bg: $primary;
// $navbar-dark-hover-color: rgba($white, .9);
// $grid-gutter-width: 32px;
// $input-border-color: $black;
// $font-size-base: .8125rem;
$h1-font-size: 55px;
$h2-font-size: 40px;
$h3-font-size: 32px;
$h4-font-size: 24px;
$lead-600-font-size: 24px;
$lead-400-font-size: 20px;
$base-font-size: 16px;
$italic-font-size: 20px;
$small-font-size: 14px;
// $headings-line-height: 1.25;
// $btn-border-radius: 24px;
// $btn-border-radius-lg: 24px;
// $btn-border-radius-sm: 24px;
// $card-border-radius: 16px;
// $input-border-radius: 0px;
// $font-family-sans-serif: avenir;
// $font-family-monospace: avenir;
// $enable-responsive-font-sizes: true;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    4: (
      $spacer * 0.25,
    ),
    8: (
      $spacer * 0.5,
    ),
    16: (
      $spacer,
    ),
    24: (
      $spacer * 1.5,
    ),
    32: (
      $spacer * 2,
    ),
    40: (
      $spacer * 2.5,
    ),
    48: (
      $spacer * 3,
    ),
    56: (
      $spacer * 3.5,
    ),
    64: (
      $spacer * 4,
    ),
    72: (
      $spacer * 4.5,
    ),
    80: (
      $spacer * 5,
    ),
    88: (
      $spacer * 5.5,
    ),
    96: (
      $spacer * 6,
    ),
    104: (
      $spacer * 6.5,
    ),
    112: (
      $spacer * 7,
    ),
    120: (
      $spacer * 7.5,
    ),
    128: (
      $spacer * 8,
    ),
    136: (
      $spacer * 8.5,
    ),
    144: (
      $spacer * 9,
    ),
    152: (
      $spacer * 9.5,
    ),
    160: (
      $spacer * 10,
    ),
    168: (
      $spacer * 10.5,
    ),
    176: (
      $spacer * 11,
    ),
    184: (
      $spacer * 11.5,
    ),
    192: (
      $spacer * 12,
    ),
    208: (
      $spacer * 13,
    ),
    224: (
      $spacer * 14,
    ),
    264: (
      $spacer * 16.5,
    ),
    304: (
      $spacer * 19,
    ),
    320: (
      $spacer * 20,
    ),
    344: (
      $spacer * 21.5,
    ),
    352: (
      $spacer * 22,
    ),
    368: (
      $spacer * 23,
    ),
    384: (
      $spacer * 24,
    ),
    400: (
      $spacer * 25,
    ),
    408: (
      $spacer * 25.5,
    ),
    464: (
      $spacer * 29,
    ),
    480: (
      $spacer * 30,
    ),
    496: (
      $spacer * 31,
    ),
    512: (
      $spacer * 32,
    ),
    520: (
      $spacer * 32.5,
    ),
    528: (
      $spacer * 33,
    ),
    544: (
      $spacer * 34,
    ),
    640: (
      $spacer * 40,
    ),
    688: (
      $spacer * 43,
    ),
    704: (
      $spacer * 44,
    ),
    720: (
      $spacer * 45,
    ),
    800: (
      $spacer * 50,
    ),
    880: (
      $spacer * 55,
    ),
    1200: (
      $spacer * 75,
    ),
  ),
  $spacers
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 2400px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
) !default;

@font-face {
  font-family: 'JosefinSans';
  src: url('../fonts/JosefinSans-Regular.eot');
  src:
    url('../fonts/JosefinSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/JosefinSans-Regular.woff2') format('woff2'),
    url('../fonts/JosefinSans-Regular.woff') format('woff'),
    url('../fonts/JosefinSans-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JosefinSans-Bold';
  src: url('../fonts/JosefinSans-Bold.eot');
  src:
    url('../fonts/JosefinSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/JosefinSans-Bold.woff2') format('woff2'),
    url('../fonts/JosefinSans-Bold.woff') format('woff'),
    url('../fonts/JosefinSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JosefinSans-SemiBoldItalic';
  src: url('../fonts/JosefinSans-SemiBoldItalic.eot');
  src:
    url('../fonts/JosefinSans-SemiBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/JosefinSans-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/JosefinSans-SemiBoldItalic.woff') format('woff'),
    url('../fonts/JosefinSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'JosefinSans-SemiBold';
  src: url('../fonts/JosefinSans-SemiBold.eot');
  src:
    url('../fonts/JosefinSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/JosefinSans-SemiBold.woff2') format('woff2'),
    url('../fonts/JosefinSans-SemiBold.woff') format('woff'),
    url('../fonts/JosefinSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

$font-family-base: 'JosefinSans';

.h1 {
  font-family: 'JosefinSans-Bold' !important;
  font-size: $h1-font-size !important;
  line-height: 65px !important;
}

.h2 {
  font-family: 'JosefinSans-Bold' !important;
  font-size: $h2-font-size !important;
  line-height: 40px !important;
}

.h3 {
  font-family: 'JosefinSans-Bold' !important;
  font-size: $h3-font-size !important;
  line-height: 40px !important;
}

.h4 {
  font-family: 'JosefinSans-SemiBold' !important;
  font-size: $h4-font-size !important;
  line-height: 32px !important;
}

.lead-600 {
  font-family: 'JosefinSans-SemiBold' !important;
  font-size: $lead-600-font-size !important;
  line-height: 26px !important;
}

.lead-400 {
  font-family: 'JosefinSans' !important;
  font-size: $lead-400-font-size !important;
  line-height: 26px !important;
}

.base-bold {
  font-family: 'JosefinSans-Bold' !important;
  font-size: $base-font-size !important;
  line-height: 18px !important;
}

.base {
  font-family: 'JosefinSans' !important;
  font-size: $base-font-size !important;
  line-height: 18px !important;
}

.base-underlined {
  font-family: 'JosefinSans-Bold' !important;
  font-size: $base-font-size !important;
  line-height: 22px !important;
  text-decoration: underline;
}

.italic {
  font-family: 'JosefinSans-SemiBoldItalic' !important;
  font-size: $italic-font-size !important;
  line-height: 26px !important;
}

.small {
  font-family: 'JosefinSans-SemiBold' !important;
  font-size: $small-font-size !important;
  line-height: 18px !important;
  font-weight: 400 !important;
}

.btn-outline-primary {
  background-color: $secondary !important;
  color: $primary !important;
  border: 4px solid $secondary !important;
  border-radius: 0px !important;

  &:hover,
  &:active {
    color: $white !important;
  }
}

.btn-outline-secondary {
  background-color: $primary !important;
  color: $secondary-light !important;
  border: 4px solid $primary !important;
  border-radius: 0px !important;

  &:hover,
  &:active {
    color: $white !important;
  }
}

.btn-secondary-light {
  background-color: $secondary-light !important;
  color: $primary !important;
  border: 4px solid $secondary-light !important;
  border-radius: 0px !important;

  &:hover,
  &:active {
    color: $white !important;
  }
}
