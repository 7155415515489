@import './_vars.scss';

.rbc-calendar {
  background-color: $light;
  max-height: 360px;

  .rbc-toolbar {
    position: relative;

    .rbc-toolbar-label {
      order: 0;
      text-align: left !important;
      padding: 20px;
    }

    .rbc-btn-group {
      position: absolute;
      right: 50px;
      top: 20px;
      order: 1;

      &:first-child {
        button {
          border: none !important;

          &:first-child {
            display: none;
          }

          &:nth-child(2) {
            position: absolute;
            visibility: hidden;
            right: 45px;

            &::before {
              content: '<';
              visibility: visible;
            }
          }

          &:last-child {
            position: absolute;
            visibility: hidden;

            &::before {
              content: '>';
              visibility: visible;
            }
          }
        }
      }

      &:last-child {
        button {
          &:not(:first-child) {
            display: none;
          }
        }
      }
    }
  }

  .rbc-month-view {
    border: none;

    .rbc-month-header {
      .rbc-header {
        border: none;
      }
    }

    .rbc-month-row {
      border: none;

      .rbc-row-bg {
        .rbc-day-bg {
          border: none;
        }
      }

      .rbc-row-content {
        .rbc-row {
          .rbc-date-cell {
            text-align: center;
          }
        }
      }
    }
  }

  .rbc-event {
    background-color: $secondary !important;
  }
}
