@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,600;0,700;1,500&display=swap');
@import 'vars';

body {
  font-family: 'Josefin Sans', sans-serif;
}

.video-container {
  padding-bottom: 45%;

  // @media only screen and (max-width: 2200px) {
  //   height: 50rem;
  // }

  // @media only screen and (max-width: 1920px) {
  //   height: 28.125rem;
  // }

  // @media only screen and (max-width: 1700px) {
  //   height: 25rem;
  // }

  @media only screen and (max-width: 1440px) {
    padding-bottom: 50%;
  }

  @media only screen and (max-width: 1200px) {
    padding-bottom: 60%;
  }

  @media only screen and (max-width: 1024px) {
    padding-bottom: 75%;
  }

  @media only screen and (max-width: 765px) {
    padding-bottom: 100%;
  }

  .embeddedVideo {
    height: 80%;
    max-height: 600px;

    @media only screen and (max-width: 765px) {
      height: 90%;
    }
  }
}

.exercise1Tab {
  height: 100%;

  @media only screen and (max-width: 1440px) {
    height: 100vh;
  }
}

.exercise1ReviewTab {
  height: 100%;

  @media only screen and (max-width: 1440px) {
    // height: 100vh;
  }
}

.exercise2Tab {
  height: 100%;

  @media only screen and (max-width: 1440px) {
    height: 100vh;
  }
}

.exercise2ReviewTab {
  height: 100%;
}

.input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: none;
}

.form-check-input:checked[type='checkbox'] {
  background-color: #33394a !important;
}

.custom-shadow {
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
}

#custom-switch {
  height: 1.5rem;
  width: 3rem;
}

.custom-image-padding {
  @media only screen and (min-width: 310px) {
    padding-left: 5.75rem;
    padding-right: 5.75rem;
  }
  @media only screen and (min-width: 768px) {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  @media only screen and (min-width: 768px) {
    padding-left: 13.5rem;
    padding-right: 13.5rem;
  }
  @media only screen and (min-width: 992px) {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
  @media only screen and (min-width: 1100px) {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  @media only screen and (min-width: 1200px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @media only screen and (min-width: 1300px) {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  @media only screen and (min-width: 1400px) {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
  @media only screen and (min-width: 1500px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  @media only screen and (min-width: 1600px) {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  @media only screen and (min-width: 1700px) {
    padding-left: 10.5rem;
    padding-right: 10.5rem;
  }
  @media only screen and (min-width: 1800px) {
    padding-left: 13.5rem;
    padding-right: 13.5rem;
  }
}

.progress-modal-position {
  @media only screen and (min-width: 992px) {
    right: -7.25rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1100px) {
    right: -6.5rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1200px) {
    right: -6rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1300px) {
    right: -5.5rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1400px) {
    right: -4.5rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1500px) {
    right: -4rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1600px) {
    right: -3rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1700px) {
    right: -3rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1800px) {
    right: -1rem;
    top: -1rem;
  }
}

.explore-modal-position {
  @media only screen and (min-width: 992px) {
    right: -15rem;
    top: -19rem;
  }
  @media only screen and (min-width: 1100px) {
    right: -18rem;
    top: -19rem;
  }
  @media only screen and (min-width: 1300px) {
    right: -23rem;
    top: -19rem;
  }
  @media only screen and (min-width: 1500px) {
    right: -30rem;
    top: -19rem;
  }
}

.schedule-modal-position {
  @media only screen and (min-width: 992px) {
    right: 2.5rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1100px) {
    right: 1rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1200px) {
    right: -0.5rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1300px) {
    right: -2rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1400px) {
    right: -3.65rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1500px) {
    right: -5.25rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1600px) {
    right: -6.5rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1700px) {
    right: -7.75rem;
    top: -1rem;
  }
  @media only screen and (min-width: 1800px) {
    right: -9rem;
    top: -1rem;
  }
}

.documents-modal-position {
  @media only screen and (min-width: 992px) {
    right: -10.25rem;
    top: -2rem;
  }
  @media only screen and (min-width: 1100px) {
    right: -9.5rem;
    top: -2rem;
  }
  @media only screen and (min-width: 1300px) {
    right: -8rem;
    top: -2rem;
  }
  @media only screen and (min-width: 1500px) {
    right: -6.5rem;
    top: -2rem;
  }
  @media only screen and (min-width: 1700px) {
    right: -5rem;
    top: -2rem;
  }
}

.video-recap-modal-position {
  @media only screen and (min-width: 992px) {
    right: -15rem;
    top: -18rem;
  }
  @media only screen and (min-width: 1100px) {
    right: -18rem;
    top: -18rem;
  }
  @media only screen and (min-width: 1300px) {
    right: -23rem;
    top: -18rem;
  }
  @media only screen and (min-width: 1500px) {
    right: -30rem;
    top: -18rem;
  }
}

.snipet-modal-position {
  @media only screen and (min-width: 992px) {
    right: 4rem;
    top: -15rem;
  }
  @media only screen and (min-width: 1100px) {
    right: 2.3rem;
    top: -15rem;
  }
  @media only screen and (min-width: 1200px) {
    right: 1.1rem;
    top: -15rem;
  }
  @media only screen and (min-width: 1300px) {
    right: 0rem;
    top: -15rem;
  }
  @media only screen and (min-width: 1400px) {
    right: -1rem;
    top: -15rem;
  }
  @media only screen and (min-width: 1500px) {
    right: -2.75rem;
    top: -15rem;
  }
  @media only screen and (min-width: 1600px) {
    right: -4.5rem;
    top: -15rem;
  }
  @media only screen and (min-width: 1700px) {
    right: -6.25rem;
    top: -15rem;
  }
  @media only screen and (min-width: 1800px) {
    right: -8rem;
    top: -15rem;
  }
}

.alerts-modal-position {
  top: 4rem;
}

.control-panel-modal-position {
  @media only screen and (min-width: 992px) {
    right: 6.25rem;
    top: 0;
  }
  @media only screen and (min-width: 1100px) {
    right: 2.5rem;
    top: 0;
  }
  @media only screen and (min-width: 1200px) {
    right: -0.15;
    top: 0;
  }
  @media only screen and (min-width: 1300px) {
    right: -2.5rem;
    top: 0;
  }
  @media only screen and (min-width: 1400px) {
    right: -5.5rem;
    top: 0;
  }
  @media only screen and (min-width: 1500px) {
    right: -8rem;
    top: 0;
  }
  @media only screen and (min-width: 1600px) {
    right: -10.5rem;
    top: 0;
  }
  @media only screen and (min-width: 1700px) {
    right: -12.75rem;
    top: 0;
  }
  @media only screen and (min-width: 1800px) {
    right: -15rem;
    top: 0;
  }
}

.progress-arrow-position {
  @media only screen and (min-width: 992px) {
    left: 11.5rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1015px) {
    left: 13.5rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1105px) {
    left: 15.5rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1168px) {
    left: 17.5rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1250px) {
    left: 19.5rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1330px) {
    left: 21.5rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1425px) {
    left: 23.5rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1475px) {
    left: 25rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1555px) {
    left: 27rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1650px) {
    left: 29rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1715px) {
    left: 31rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1840px) {
    left: 33rem;
    top: 19rem;
  }
}

.explore-arrow-position {
  @media only screen and (min-width: 992px) {
    left: 11.5rem;
    top: 19rem;
  }
  @media only screen and (min-width: 4px) {
    left: 13.5rem;
    top: 19rem;
  }
  @media only screen and (min-width: 5px) {
    left: 15.5rem;
    top: 19rem;
  }
  @media only screen and (min-width: 6px) {
    visibility: hidden;
  }
  @media only screen and (min-width: 1100px) {
    visibility: visible;
    left: 27rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1203px) {
    left: 30rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1300px) {
    left: 38.2rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1392px) {
    left: 41rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1500px) {
    left: 51.3rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1538px) {
    left: 52.5rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1615px) {
    left: 55rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1695px) {
    left: 57.5rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1778px) {
    left: 60rem;
    top: 19rem;
  }
  @media only screen and (min-width: 1855px) {
    left: 62.5rem;
    top: 19rem;
  }
}

.nav-tabs {
  .nav-item {
    .nav-link {
      cursor: pointer;
      color: #626873;
      opacity: 0.6;

      &.active {
        color: #33394a;
        opacity: 1;
      }
    }
  }
}

.session-row {
  &:hover {
    background: rgba(98, 104, 115, 0.2);
  }
}
.calendar {
  border: 0;
  .calendar-header {
    background: rgba(98, 104, 115, 0.2);
    .calendar-title {
      font-size: 1.5rem;
    }
  }
  .calendar-body {
    background: rgba(98, 104, 115, 0.2);
    grid-auto-rows: minmax(50px, auto);
    .day-name {
      border: 0;
    }
    .day {
      text-align: center;
      border: 0;
    }
  }
}

.tab-background-none {
  background-color: transparent !important;
  border: none !important;
  align-items: center;
}

.scale-chart-cell {
  background-color: white;
}

.scale-chart-cell:hover {
  background-color: #626873;
  color: white;
}

.back-button {
  text-decoration: none;
}

.back-button:hover {
  border-bottom: 1px solid $secondary;
}

.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
  font-size: 16px;
  margin-left: 20px;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 2px solid $secondary-light;
  border-radius: 4px;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: $light;
  border: 2px solid $secondary-light;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  font-size: 20px;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.package-back-button {
  color: $secondary;
}

.session-main-screen {
  color: $secondary;
  font-size: 40px;
  margin-bottom: 20px;
}

.session-main-session-title {
  color: $secondary-light;
  font-size: 60px;
}

.sidebar-button {
  position: fixed;
  top: 50%;
  right: 0px;
  padding: 30px 10px 30px 10px;
  border-radius: 8px 0px 0px 8px;
}

.offcanvas.offcanvas-end {
  background-color: rgba(239, 240, 242, 1);
  width: 650px;
  overflow: auto;
}

.close-button {
  position: absolute;
  top: 50%;
  right: 649px;
  border: none;
  cursor: pointer;
  padding: 30px 10px 30px 10px;
  border-radius: 8px 0px 0px 8px;
}

.sidebar-title {
  size: 50px;
}

.custom-table-header {
  background-color: #dad8d8;
}

.custom-table-header thead {
  background-color: white;
}

.custom-table-styles tbody tr td {
  padding: 20px;
}

.row-divider {
  border-top: 20px solid white;
}

.selected-checkbox {
  background-color: burlywood;
}

/* 
input[type='checkbox'] {
  width: 20px;
  height: 20px;
  background-color: #fa9e57;
}
 */

input[type='checkbox']:checked {
  accent-color: $secondary-light;
}
